import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '@shared/components/layout/layout';
import { CIA_TEMPLATE } from '@shared/utils/constants';
import { useGlobalFooter } from '@shared/hooks/use-global-footer';

const Unavaiable = () => {
  const data = useStaticQuery(graphql`
    query NotFoundData {
      header: wordpressWpMenu(slug: {eq: "cia-menu"}) {
        ...Header
        menuItems {
          nodes {
            url
            uri
            path
            parentId
            parentDatabaseId
            label
            databaseId
            description
            childItems {
              nodes {
                id
                url
                uri
                path
                label
                parentDatabaseId
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout headerMenu={data.header?.menuItems?.nodes} template={CIA_TEMPLATE} footerMenu={useGlobalFooter()}>
      <section className="background-grid-black background-threads careers-threads">
        <div className="container pv120-90-60">
          <div className="row body-space">
            <div className="col-12 align-middle">
              <p className="h3-style">
                Unavailable
              </p>
              <p>We apologize for the inconvenience, but the link you are trying to access is undergoing scheduled maintenance. During this time, access to the page is temporarily unavailable. We appreciate your patience and understanding, and please check back shortly.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Unavaiable;
